<template>
  <div>
    <div class="card-body">

      <div v-if="!isAvalibleStatus" class="alert alert-warning mb-0">
        <i class="fas fa-exclamation-triangle"></i> ไม่สามารถออกผลได้ ({{roundStatus}})
      </div>
      <div v-else-if="!isRoundClosed" class="alert alert-warning mb-0">
        <i class="fas fa-exclamation-triangle mr-2"></i>
        <RoundStatus v-if="round" :data="round" @cssStyle="setStatusStyle" class="badge badge-status font-weight-normal" :class="[cssStatusClass.bg, cssStatusClass.text]" style="font-size: 100%;" />
        ยังไม่ถึงเวลาออกผล
      </div>

      <div v-else>
        <div v-if="isPaid" class="alert alert-success mb-4">
          <i class="far fa-check-circle"></i> จ่ายเงินแล้ว
        </div>

        <div>
          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-danger font-weight-bold mb-0">2 ตัวล่าง</label>
            </div>
            <div class="col-sm-9 form-inline">
              <span v-if="isPaid" class="badge badge-danger mr-1">{{award.two}}</span>
              <b-form-input
                v-else
                placeholder="2 ตัวล่าง"
                v-model="award.two"
                v-on:keydown="keypress"
                maxlength="2"
                :disabled="!(resultStep==='waitResult' || !resultStep) && resultStep !== 'Input-bottom'"
              ></b-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-danger font-weight-bold mb-0">วิ่งล่าง</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in runBottom" class="badge badge-danger mr-1">{{number}}</span>
            </div>
          </div>

          <div v-if="isAvalibleStatus && isRoundClosed && !isPaid" class="row justify-content-end mt-2">
            <div class="col-sm-9 text-right">
              <b-button
                variant="danger"
                size="sm"
                @click="saveResults('bottom')"
                :disabled="!(resultStep==='waitResult' || !resultStep) && resultStep !== 'Input-bottom'"
              ><i class="far fa-save"></i> บันทึกผลเลขล่าง</b-button>
            </div>
          </div>
        </div>

        <hr>

        <div>
          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">3 ตัวบน</label>
            </div>
            <div class="col-sm-9 form-inline">
              <span v-if="isPaid" class="badge badge-success mr-1">{{award.three}}</span>
              <b-form-input
                v-else
                placeholder="3 ตัวบน"
                v-model="award.three"
                v-on:keydown="keypress"
                maxlength="3"
                :disabled="resultStep!=='LoseItems-runBottom' && resultStep!=='Input-top'"
              >
              </b-form-input>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">3 ตัวโต๊ด</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in threeNumberTode" class="badge badge-success mr-1">{{number}}</span>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">2 ตัวบน</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in twoNumberTop" class="badge badge-success mr-1">{{number}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">วิ่งบน</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in runTop" class="badge badge-success mr-1">{{number}}</span>
            </div>
          </div>

          <div v-if="isAvalibleStatus && isRoundClosed && !isPaid" class="row justify-content-end mt-2">
            <div class="col-sm-9 text-right">
              <b-button
                variant="success"
                size="sm"
                @click="saveResults('top')"
                :disabled="resultStep!=='LoseItems-runBottom' && resultStep!=='Input-top'"
              ><i class="far fa-save"></i> บันทึกผลเลขบน</b-button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-if="isAvalibleStatus && resultStep" class="card-footer p-3">
      <div>
        <b-button
          :variant="resultStep==='Input-bottom'?'info':'outline-info'"
          :disabled="resultStep!='Input-bottom'"
          size="sm"
          @click="ProcessingTickets"
        >ปรับสถานะโพย</b-button>
      </div>

      <div class="mt-3">

        <b-button
          :variant="resultStep==='ProcessingTickets'?'info':'outline-info'"
          :disabled="resultStep!='ProcessingTickets'"
          size="sm"
          @click="ProcessingItems('twoNumberBottom')"
        >ปรับสถานะเลข 2 ตัวล่าง</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='ProcessingItems-twoNumberBottom'?'info':'outline-info'"
          :disabled="resultStep!='ProcessingItems-twoNumberBottom'"
          size="sm"
          @click="ProcessingItems('runBottom')"
        >ปรับสถานะเลขวิ่งล่าง</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='ProcessingItems-runBottom'?'info':'outline-info'"
          :disabled="resultStep!='ProcessingItems-runBottom'"
          size="sm"
          @click="WonItems('twoNumberBottom')"
        >เลขถูก 2 ตัวล่าง</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='twoNumberBottom'?'info':'outline-info'"
          :disabled="resultStep!='twoNumberBottom'"
          size="sm"
          @click="WonItems('runBottom')"
        >เลขถูกวิ่งล่าง</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='runBottom'?'info':'outline-info'"
          :disabled="resultStep!='runBottom'"
          size="sm"
          @click="LoseItems('twoNumberBottom')"
        >เลขไม่ถูกรางวัล 2 ตัวล่าง</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='LoseItems-twoNumberBottom'?'info':'outline-info'"
          :disabled="resultStep!='LoseItems-twoNumberBottom'"
          size="sm"
          @click="LoseItems('runBottom')"
        >เลขไม่ถูกรางวัลวิ่งล่าง</b-button>
      </div>

      <div class="mt-3">
        <b-button
          :variant="resultStep==='Input-top'?'info':'outline-info'"
          :disabled="resultStep!='Input-top'"
          size="sm"
          @click="ProcessingItems('threeNumberTop')"
        >ปรับสถานะเลข 3 ตัวบน</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='ProcessingItems-threeNumberTop'?'info':'outline-info'"
          :disabled="resultStep!='ProcessingItems-threeNumberTop'"
          size="sm"
          @click="ProcessingItems('threeNumberTode')"
        >ปรับสถานะเลข 3 ตัวโต๊ด</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='ProcessingItems-threeNumberTode'?'info':'outline-info'"
          :disabled="resultStep!='ProcessingItems-threeNumberTode'"
          size="sm"
          @click="ProcessingItems('twoNumberTop')"
        >ปรับสถานะเลข 2 ตัวบน</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='ProcessingItems-twoNumberTop'?'info':'outline-info'"
          :disabled="resultStep!='ProcessingItems-twoNumberTop'"
          size="sm"
          @click="ProcessingItems('runTop')"
        >ปรับสถานะเลขวิ่งบน</b-button>
      </div>

      <div class="mt-3">
        <b-button
          :variant="resultStep==='ProcessingItems-runTop'?'info':'outline-info'"
          :disabled="resultStep!='ProcessingItems-runTop'"
          size="sm"
          @click="WonItems('threeNumberTop')"
        >เลขถูก 3 ตัวบน</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='threeNumberTop'?'info':'outline-info'"
          :disabled="resultStep!='threeNumberTop'"
          size="sm"
          @click="WonItems('threeNumberTode')"
        >เลขถูก 3 ตัวโต๊ด</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='threeNumberTode'?'info':'outline-info'"
          :disabled="resultStep!='threeNumberTode'"
          size="sm"
          @click="WonItems('twoNumberTop')"
        >เลขถูก 2 ตัวบน</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='twoNumberTop'?'info':'outline-info'"
          :disabled="resultStep!='twoNumberTop'"
          size="sm"
          @click="WonItems('runTop')"
        >เลขถูกวิ่งบน</b-button>
      </div>

      <div class="mt-3">
        <b-button
          :variant="resultStep==='runTop'?'info':'outline-info'"
          :disabled="resultStep!='runTop'"
          size="sm"
          @click="LoseItems('threeNumberTop')"
        >เลขไม่ถูกรางวัล 3 ตัวบน</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='LoseItems-threeNumberTop'?'info':'outline-info'"
          :disabled="resultStep!='LoseItems-threeNumberTop'"
          size="sm"
          @click="LoseItems('threeNumberTode')"
        >เลขไม่ถูกรางวัล 3 ตัวโต๊ด</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='LoseItems-threeNumberTode'?'info':'outline-info'"
          :disabled="resultStep!='LoseItems-threeNumberTode'"
          size="sm"
          @click="LoseItems('twoNumberTop')"
        >เลขไม่ถูกรางวัล 2 ตัวบน</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='LoseItems-twoNumberTop'?'info':'outline-info'"
          :disabled="resultStep!='LoseItems-twoNumberTop'"
          size="sm"
          @click="LoseItems('runTop')"
        >เลขไม่ถูกรางวัลวิ่งบน</b-button>
      </div>

      <div class="mt-3">
        <b-button
          :variant="resultStep==='LoseItems-runTop'?'info':'outline-info'"
          :disabled="resultStep!='LoseItems-runTop'"
          size="sm"
          @click="WonTickets()"
        >โพยถูกรางวัล</b-button>

        <span class="text-secondary"> > </span>
        <b-button
          :variant="resultStep==='WonTickets'?'info':'outline-info'"
          :disabled="resultStep!='WonTickets'"
          size="sm"
          @click="LoseTickets()"
        >โพยไม่ถูกรางวัล</b-button>
      </div>
    </div>

    <div v-if="isAvalibleStatus && resultStep" class="card-footer p-3">

      <div>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResult('cancelResult', 'ยกเลิกผล/แก้ไขผลใหม่')"
          :disabled="resultStep!=='Completed'"
          :class="resultStep==='Completed'?'btn-danger':'btn-outline-danger'"
        >
          <i class="fas fa-undo-alt"></i> แก้ไขผล/คำนวนใหม่
        </button>

        <span class="text-secondary"> > </span>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResult('rollbackCredit', 'ดึงเครดิตกลับจากสมาชิก')"
          :disabled="resultStep!=='cancelResult'"
          :class="resultStep==='cancelResult'?'btn-danger':'btn-outline-danger'"
        >
          ดึงเครดิตกลับ
        </button>

        <span class="text-secondary"> > </span>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResultTicket('ปรับสถานะโพย')"
          :disabled="resultStep!=='rollbackCredit'"
          :class="resultStep==='rollbackCredit'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตโพย
        </button>
      </div>

      <div class="mt-3">
        <button
          class="btn btn-danger btn-sm"
          @click="changeResultItem('threeNumberTop', 'ปรับสถานะเลข')"
          :disabled="resultStep!=='resetTicket'"
          :class="resultStep==='resetTicket'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตเลข 3 ตัวบน
        </button>

        <span class="text-secondary"> > </span>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResultItem('threeNumberTode', 'ปรับสถานะเลข')"
          :disabled="resultStep!=='resetItem-threeNumberTop'"
          :class="resultStep==='resetItem-threeNumberTop'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตเลข 3 ตัวโต๊ด
        </button>

        <span class="text-secondary"> > </span>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResultItem('twoNumberTop', 'ปรับสถานะเลข')"
          :disabled="resultStep!=='resetItem-threeNumberTode'"
          :class="resultStep==='resetItem-threeNumberTode'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตเลข 2 ตัวบน
        </button>

        <span class="text-secondary"> > </span>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResultItem('twoNumberBottom', 'ปรับสถานะเลข')"
          :disabled="resultStep!=='resetItem-twoNumberTop'"
          :class="resultStep==='resetItem-twoNumberTop'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตเลข 2 ตัวล่าง
        </button>

        <span class="text-secondary"> > </span>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResultItem('runTop', 'ปรับสถานะเลข')"
          :disabled="resultStep!=='resetItem-twoNumberBottom'"
          :class="resultStep==='resetItem-twoNumberBottom'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตเลขวิ่งบน
        </button>

        <span class="text-secondary"> > </span>
        <button
          class="btn btn-danger btn-sm"
          @click="changeResultItem('runBottom', 'ปรับสถานะเลข')"
          :disabled="resultStep!=='resetItem-runTop'"
          :class="resultStep==='resetItem-runTop'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตเลขวิ่งล่าง
        </button>
      </div>

      <div class="mt-3">
        <button
          class="btn btn-danger btn-sm"
          @click="changeResult('resetRound', 'ปรับสถานะงวดหวย')"
          :disabled="resultStep!=='resetItem-runBottom'"
          :class="resultStep==='resetItem-runBottom'?'btn-danger':'btn-outline-danger'"
        >
          รีเซ็ตงวดหวย
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { marketOpenBets } from "@/configs/market.config"
import LottoService from '@/services/lottoService'
import moment from '@/helpers/moment'
import Swal from 'sweetalert2'
import RoundStatus from './RoundStatus'
import cryptoRandomString from 'crypto-random-string'
import _ from 'lodash'

export default {
  name: 'ResultSingle',
  props: ['round'],
  components: {
    RoundStatus
  },
  data() {
    return {
      cssStatusClass: {
        bg: null,
        text: null,
        link: null,
        btn: null
      },
      award: {
        three: this.round.results.threeNumberTop.join(''),
        two: this.round.results.twoNumberBottom.join('')
      },
      uniqueCode: null
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    roundStatus() {
      return this.round?.status
    },
    isAvalibleStatus() {
      if(!this.round)
        return false

      return ['Open', 'Close', 'Complete', 'Paid'].includes(this.round.status)
    },
    isRoundClosed() {
      if(!this.round)
        return false

      if(moment().isBefore(this.round.roundDate.close))
        return false

      return true
    },
    isPaid() {
      return this.round?.status === 'Paid'
    },
    awardThree() {
      if(!this.award.three)
        return null

      const awards = this.award.three.slice('')
      if(awards.length !== 3)
        return null

      return awards
    },
    awardTwo() {
      if(!this.award.two)
        return null

      const awards = this.award.two.slice('')
      if(awards.length !== 2)
        return null

      return awards
    },
    threeNumberTop() {
      if(!this.awardThree)
        return []

      return [this.awardThree]
    },
    threeNumberTode() {
      if(!this.awardThree)
        return []

      const awards = [
        `${this.awardThree[0]}${this.awardThree[1]}${this.awardThree[2]}`,
        `${this.awardThree[0]}${this.awardThree[2]}${this.awardThree[1]}`,
        `${this.awardThree[1]}${this.awardThree[0]}${this.awardThree[2]}`,
        `${this.awardThree[1]}${this.awardThree[2]}${this.awardThree[0]}`,
        `${this.awardThree[2]}${this.awardThree[1]}${this.awardThree[0]}`,
        `${this.awardThree[2]}${this.awardThree[0]}${this.awardThree[1]}`,
      ]
      return _.uniqBy(awards)
    },
    twoNumberTop() {
      if(!this.awardThree)
        return []

      return [`${this.awardThree[1]}${this.awardThree[2]}`]
    },
    runTop() {
      if(!this.awardThree)
        return []

      const awards = [`${this.awardThree[0]}`, `${this.awardThree[1]}`, `${this.awardThree[2]}`]
      return _.uniqBy(awards)
    },
    twoNumberBottom() {
      if(!this.awardTwo)
        return []

      return [this.awardTwo]
    },
    runBottom() {
      if(!this.awardTwo)
        return []

      const awards = [`${this.awardTwo[0]}`, `${this.awardTwo[1]}`]
      return _.uniqBy(awards)
    },
    resultStep() {
      return this.round?.resultStep
    }
  },
  watch: {
    round() {
      if(this.round) {
        this.award = {
          three: this.round.results.threeNumberTop.join(''),
          two: this.round.results.twoNumberBottom.join('')
        }
      }
    }
  },
  methods: {
    setStatusStyle(css) {
      this.cssStatusClass = {
        ...this.cssStatusClass,
        ...css
      }
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key)) {
        event.preventDefault()
      }
    },
    saveResults(pos) {

      if(pos === 'bottom' && !this.awardTwo)
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'กรุณากรอกผล'
        })

      if(pos === 'top' && !this.awardThree)
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'กรุณากรอกผล'
        })

      const results = {
        threeNumberTop: this.threeNumberTop,
        threeNumberTode: this.threeNumberTode,
        twoNumberTop: this.twoNumberTop,
        twoNumberBottom: this.twoNumberBottom,
        runTop: this.runTop,
        runBottom: this.runBottom
      }

      Swal.fire({
        title: 'ยืนยันบันทึกผล?',
        text: "กรุณาตรวจสอบผลให้ถูกต้องก่อนกดยืนยัน",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.saveResults(this.round._id, pos, results).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
            // this.$router.push({name: 'PayWinTickets'})
          })
        }
      })
    },
    ProcessingTickets() {
      Swal.fire({
        title: 'อัพเดตสถานะโพย?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.updateProcessingTickets(this.round._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    ProcessingItems(betType) {
      Swal.fire({
        title: 'อัพเดตสถานะเลข?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.updateProcessingItems(this.round._id, betType).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    WonItems(betType) {
      Swal.fire({
        title: 'อัพเดตเลขถูกรางวัล?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.updateWonItems(this.round._id, betType).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    LoseItems(betType) {
      Swal.fire({
        title: 'อัพเดตไม่ถูกรางวัล?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.updateLoseItems(this.round._id, betType).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    WonTickets() {
      Swal.fire({
        title: 'อัพเดตโพยถูกรางวัล?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.updateWonTickets(this.round._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    LoseTickets() {
      Swal.fire({
        title: 'อัพเดตโพยไม่ถูกรางวัล?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.updateLoseTickets(this.round._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกโพยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกโพยเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.$router.push({name: 'PayWonTickets'})
            // this.$emit('reload')
          })
        }
      })
    },
    changeResult(step, text) {
      Swal.fire({
        title: 'ดำเนินการต่อ!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.changeResult(this.roundId, step, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ยกเลิกจ่ายเงินไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            '',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    changeResultTicket(text) {
      Swal.fire({
        title: 'ดำเนินการต่อ!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.changeResultTicket(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ยกเลิกจ่ายเงินไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            '',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    changeResultItem(step, text) {
      Swal.fire({
        title: 'ดำเนินการต่อ!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.changeResultItem(this.roundId, step, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ยกเลิกจ่ายเงินไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            '',
            'success'
          )
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    }
  },
  mounted() {
    this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 1px;
}
</style>
